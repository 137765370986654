import React from "react"
import PropTypes from "prop-types"
import Image from "gatsby-image"

const WelcomeBanners = props => {
  const { bannerTitle, bannerSubTitle, bannerImage, buttonTitle } = props
  return (
    <div>
      <div className="WelcomeBanner">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1 className="Title">{bannerTitle}</h1>
              {bannerSubTitle && <h3 className="SubTitle">{bannerSubTitle}</h3>}
              {buttonTitle && (
                <button className="btn btn-theme btn-light mt-3">
                  {buttonTitle}
                </button>
              )}
            </div>
            <div className="col-md-6">
              <div className="WelcomeImg">
                {typeof bannerImage === "string" ? (
                  <img
                    src={bannerImage}
                    alt={bannerTitle}
                    className="img-fluid"
                  />
                ) : (
                  <Image fluid={bannerImage} alt={bannerTitle} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

WelcomeBanners.propTypes = {
  bannerTitle: PropTypes.string,
  bannerSubTitle: PropTypes.string,
  bannerImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  buttonTitle: PropTypes.string,
}

export default WelcomeBanners
