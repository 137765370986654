import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import WelcomeBanner from "../components/WelcomeBanners"
import LifeAtGallery from "../components/LifeAtBrainyfied/LifeAtGallery"
import lifeQuery from "../query/lifeQuery"

const LifeatEducorp = () => {
  const { life } = lifeQuery()
  const lifeData = life.edges[0].node
  return (
    <Layout>
      <SEO title="LifeatEducorp" />
      <WelcomeBanner
        bannerTitle={lifeData.lifeBannerTitle}
        bannerSubTitle={lifeData.lifeBannerSubTitle}
        bannerImage={lifeData.lifeBannerImage}
      />
      <LifeAtGallery lifeContent={lifeData.lifeContent}></LifeAtGallery>
    </Layout>
  )
}

export default LifeatEducorp
