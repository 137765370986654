import { graphql, useStaticQuery } from "gatsby"

const LifeQuery = () => useStaticQuery(graphql`
query{
    life:allJsonDataJson(filter:{lifeBannerTitle:{ne:null}}){
      edges{
        node{
          lifeBannerTitle
          lifeBannerImage
          lifeBannerSubTitle
          lifeContent{
            img1
            img2
            img3
            title
            description
          }
        }
      }
    }
  }`)

  export default LifeQuery