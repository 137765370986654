import React from "react"
import PropTypes from "prop-types"

const LifeAtGallery = ({lifeContent}) => {
  return (
    <div>
      <div className="LifeAt">
        <div className="container">
        {lifeContent&&lifeContent.length>0&&lifeContent.map((lideData,index)=>(
              <div className="LifeAtGallery" key={index}>
              <div className="row">
                <div className="col-md-8">
                  <div className="LifeAtImg XL">
                    <img
                      src={lideData.img1}
                      alt="Life At Brainyfied "
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="LifeAtImg">
                    <img
                      src={lideData.img2}
                      alt="Life At Brainyfied "
                      className="img-fluid"
                    />
                  </div>
                  <div className="LifeAtImg">
                    <img
                      src={lideData.img3}
                      alt="Life At Brainyfied "
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
              <h2 className="LifeAtHead">{lideData.title}</h2>
              <p>{lideData.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

LifeAtGallery.propTypes = {
  lifeContent: PropTypes.array,
}

export default LifeAtGallery
